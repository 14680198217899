.nota-container {
	color: var(--dark-black);
	letter-spacing: 0;
	text-align: left;
	width: 100%;
}

.img { 
	width: 100%;
	height: auto;
	object-fit: cover;
}

.nota-container-text {
	margin-bottom: 0rem;
	width: 100%;
	position: relative;
}

.nota-title {
	font-size: 0.95rem;
    padding: 0.75rem 0;
	color: var(--medium-black);
    margin: 0 0.5rem 0 0;
    line-height: 1.1;
	width: 100%;
}

.nota-summary {
	display: none;
	font-size: 1rem;
	line-height: 1.375;
	color: var(--gray-text);
}
.background{
	background: #fff000;
	padding: 20px 10px;
}
.nota-autor {
	display: none;
	margin-top: 1rem;
	color: var(--medium-black);
    font-weight: 300;
    font-size: .8rem;
}
.nota-autor a{
	font-weight: 700;
}
.display-none {
	display: none;
}
.blue-txt { 
	color: var(--sky-blue);
	font-size: 0.95rem;
	letter-spacing: -0.6px;
	margin-right: 5px;
	font-weight: 800;
	letter-spacing: 0px;
}

.nota-container-text h2 {
	margin:0 0 0 0.5rem;
	width: 100%;
	font-size: 1rem;
	line-height: 1;
}


.patrocinado{
	display: flex;
    align-items: center;
    position: absolute;
	-moz-transform: translateY(-140%);
    -ms-transform: translateY(-140%);
    -o-transform: translateY(-140%);
    -webkit-transform: translateY(-140%);
    left: .5rem;
    background-color: #000000b8;    
    color: var(--white);
    padding: .5rem .5rem;
    border-radius: 4px;
    font-size: .55rem;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 400;
}

.img-container {
	display: none;
	width: 300px;
    height: auto;
    float: left;
	overflow: hidden;
    margin: 0 1rem 0 0;
	}

@media (max-width: 1024px) {
.contenedor-nota-bullet {
	border-bottom: 0px solid var(--shadow-gray); 
	padding: 0;
	height: min-content;
}
}


@media (max-width: 768px) {
	.img-container{
		/* min-height: 0px; */
		width: 100%;
		height: auto;
	}
	.nota-container-text {
		margin-bottom: 0rem;
		margin-right: 0.5rem;
        max-width: fit-content;
    }
	
	h2 .nota-title {
	font-size: 0.85rem;
    padding: 0.25rem 0 0.75rem 0;
    font-weight: 500;
	color: var(--medium-black);
	margin: 0 0.25rem;
    line-height: 1.3;
	}
	.nota-summary {
		margin: 0rem 0;
	}
	.nota-autor {
		margin-top: 0.6rem;
	}

	.contenedor-nota-bullet {
		border-bottom: 0;
		height: min-content;
		padding: 0;
		margin-bottom: 0rem;
	}

	.blue-txt { 
		color: var(--sky-blue);
		margin-right: 5px;
		font-weight: 800;
		letter-spacing: 0px;
	}
	
}